#about-summary {
  max-width: 30vw;
}

@media screen and (max-width: 480px) {
  #about-summary {
    max-width: 80vw;
    /* text-align: center; */
    margin-bottom: 1rem;
  }
}
