.homepage-header {
  display: flex;
  justify-content: space-around;
  margin-bottom: 3rem;
}

main {
  display: flex;
  flex-direction: column;
}

main > div {
  display: flex;
  justify-content: space-around;
}

@media screen and (max-width: 480px) {
  .homepage-header {
    flex-direction: column;
    align-items: center;
    margin-bottom: 0;
  }

  main {
    height: auto;
  }

  main > div {
    flex-direction: column;
    align-items: center;
  }
}
