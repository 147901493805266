footer {
  display: flex;
  flex-direction: column;
  margin-top: auto;
  padding-bottom: 5rem;
  border-top: 2px solid var(--accent-color);
}

footer > h3 {
  color: var(--text-accent-color);
  font-size: 1.25rem;
  text-decoration: underline;
  text-align: center;
  margin-bottom: 2rem;
}

footer > div {
  position: relative;
}

footer > div > div {
  display: flex;
  justify-content: center;
  gap: 4rem;
  color: var(--text-light);
}

footer > div > div > div {
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

footer > div > div > div > a > svg {
  font-size: 2rem;
}

footer > div > div > div > a > svg:hover {
  color: var(--accent-color) !important;
}

footer > .badge {
  margin-left: auto;
  position: absolute;
  right: 1rem;
}

@media screen and (max-width: 820px) {
  footer {
    padding-bottom: 1rem;
  }
  footer > .badge {
    position: initial;
    margin-right: auto;
    margin-top: 2rem;
  }
  footer > .badge > a > img {
    width: 10rem;
  }
}
