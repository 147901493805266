h1 {
  color: var(--text-accent-color);
  position: relative;
  padding-left: 1.25rem;
  font-size: 1.75rem;
}

h1 > span {
  color: white;
  font-size: 2rem;
}

h1::before {
  position: absolute;
  content: '';
  height: 100%;
  width: 0.125rem;
  background-color: var(--accent-color);
  left: 0;
}
