nav {
  display: flex;
  margin-bottom: 2rem;
  margin-top: 1rem;
  border-bottom: 2px solid var(--accent-color);
}

nav img {
  width: 75%;
  display: inline;
}

nav > ul {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  list-style-type: none;
}

nav > ul > li > * {
  text-decoration: none;
  color: var(--text-accent-color);
  font-size: 1.25rem;
}
nav > ul > li > .active {
  border-bottom: 1px solid var(--text-accent-color);
}

@media screen and (max-width: 480px) {
  nav > ul {
    padding-inline-start: 0;
  }
}
