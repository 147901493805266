.stat-blocks {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.stat-block {
  border: 1px solid var(--border-color);
  padding: 1rem;
}

.stat-block h3 {
  color: var(--text-accent-color);
}

.stat-block ul {
  list-style: none;
  text-decoration: underline;
  padding-left: 1rem;
}
