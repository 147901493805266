.carousel-container {
  display: block;
}

.carousel {
  display: flex;
  align-items: center;
  gap: 4rem;
  overflow-x: scroll;
  overflow-y: hidden;
  scroll-behavior: smooth;
}

@media screen and (max-width: 480px) {
  .carousel-container {
    position: absolute;
    bottom: 22.5%;
    width: 90vw;
  }
}

@media screen and (max-width: 768px) {
  .carousel-container {
  }
}
