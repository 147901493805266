.project-card {
  margin-top: 2rem;
  position: relative;
}

.project-image {
  max-width: 10vw;
  max-height: 10vw;
  object-fit: contain;
}

.project-card::after {
  position: absolute;
  content: attr(data-title);
  color: var(--text-accent-color);
  background-color: var(--background-color);
  border-radius: 0.5rem 0.5rem 0 0;
  border: 1px solid var(--border-color);
  border-bottom: none;
  padding: 0.25rem 0.75rem 0 0.75rem;
  left: 50%;
  bottom: 0;
  translate: -50% -0.25rem;
  font-size: 0.75rem;
  text-align: center;
}

@media screen and (max-width: 480px) {
  .project-image {
    max-width: 50vw;
    max-height: 30vw;
  }
  .project-card::after {
    width: 70%;
  }
}

@media screen and (min-width: 481px) and (max-width: 820px) {
  .project-image {
    max-width: 40vw;
    max-height: 20vw;
  }
}
