h2 {
  color: var(--accent-color);
  text-decoration: underline;
  font-size: 3rem;
}

@media screen and (max-width: 480px) {
  main {
    height: auto;
  }
}
